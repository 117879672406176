<template>
  <div
    :class="{
      'outer-container': true,
      'h-screen': isHeightOver && isDesktop,
    }"
  >
    <div class="inner-container">
      <div class="logo-container">
        <img src="../../assets/img/ntt-logo.png" alt="ntt-logo" />
      </div>
      <img
        class="red-cloud desktop"
        src="../../assets/img/red-cloud.png"
        alt="red-cloud"
      />
      <img
        class="ntt-icon desktop"
        src="../../assets/img/ntt-icon.png"
        alt="ntt-icon"
      />
      <img
        class="search-icon"
        src="../../assets/img/icon-search.png"
        alt="search-icon"
        @click.stop="showSearchModal"
      />
      <!-- Table Layout -->
      <div
        class="table-layout-container desktop"
        :style="{ transform: `scale(${width})` }"
      >
        <img
          class="red-scroll red-scroll__left"
          src="../../assets/img/red-scroll-left.png"
          alt="red-scroll-left"
        />
        <img
          class="red-scroll red-scroll__right"
          src="../../assets/img/red-scroll-right.png"
          alt="red-scroll-right"
        />
        <div class="table-layout">
          <div class="main-container">
            <div class="border-led border-top-right-led-wall" />
            <div class="border-led border-bottom-right-led-wall" />
            <div class="border-led border-left-led-display-panel" />
            <div class="led-display-panel">LED Display Panel</div>
            <div class="bar-container">
              <div class="bar">
                <div class="rectangle" />
                Dragon Bar<br />&#40;酒龍吧&#41;
              </div>
              <div class="bar">
                <div class="rectangle" />
                The<br />Balvenie<br />Bar
              </div>
            </div>
            <div class="door-container">
              <div class="door door-right" />
              <div class="door door-left" />
            </div>
            <div class="arrow-up" />
            <p class="ballroom-entrance">Ballroom<br />Entrance</p>
            <div class="section-container">
              <div class="top-section">
                <div
                  v-for="table in tableData.filter(
                    (table, index) => index < 27
                  )"
                  :key="table.table_name"
                  class="table-container"
                >
                  <div
                    class="table-wrapper"
                    @click.stop="showModal(table.table_name)"
                  >
                    <div class="table">
                      <div>{{ table.table_name }}</div>
                    </div>
                    <div
                      v-if="
                        table.table_name === '5' ||
                        table.table_name === '29' ||
                        table.table_name === '25' ||
                        table.table_name === '17' ||
                        table.table_name === '35' ||
                        table.table_name === '31'
                      "
                    >
                      <div
                        v-for="chairIndex in 11"
                        :key="chairIndex"
                        :class="
                          'chair chair-extra chair-extra__' +
                          chairIndex +
                          (chairIndex <= table.total_checkin
                            ? ' checked-in'
                            : '')
                        "
                      >
                        <div class="chair-handler-container">
                          <span class="chair-handler"></span>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        v-for="chairIndex in 10"
                        :key="chairIndex"
                        :class="
                          'chair chair__' +
                          chairIndex +
                          (chairIndex <= table.total_checkin
                            ? ' checked-in'
                            : '')
                        "
                      >
                        <div class="chair-handler-container">
                          <span class="chair-handler"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bottom-section">
                <div
                  v-for="table in tableData.filter(
                    (table, index) => index > 26
                  )"
                  :key="table.table_name"
                  class="table-container"
                >
                  <div
                    class="table-wrapper"
                    @click.stop="showModal(table.table_name)"
                  >
                    <div class="table">
                      <div>{{ table.table_name }}</div>
                    </div>
                    <div
                      v-if="
                        table.table_name === '5' ||
                        table.table_name === '29' ||
                        table.table_name === '25' ||
                        table.table_name === '17' ||
                        table.table_name === '35' ||
                        table.table_name === '31'
                      "
                    >
                      <div
                        v-for="chairIndex in 11"
                        :key="chairIndex"
                        :class="
                          'chair chair-extra chair-extra__' +
                          chairIndex +
                          (chairIndex <= table.total_checkin
                            ? ' checked-in'
                            : '')
                        "
                      >
                        <div class="chair-handler-container">
                          <span class="chair-handler"></span>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        v-for="chairIndex in 10"
                        :key="chairIndex"
                        :class="
                          'chair chair__' +
                          chairIndex +
                          (chairIndex <= table.total_checkin
                            ? ' checked-in'
                            : '')
                        "
                      >
                        <div class="chair-handler-container">
                          <span class="chair-handler"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right-container">
            <div class="led-wall">LED<br />Wall</div>
            <div class="stage">Stage</div>
            <div class="led-wall">LED<br />Wall</div>
            <div class="led-wall-text">LED Wall</div>
          </div>
        </div>
      </div>
      <div
        class="table-layout-container mobile"
        :style="{ transform: `scale(${width})` }"
      >
        <div class="table-layout">
          <div class="top-container">
            <div class="led-wall">LED Wall</div>
            <div class="stage">Stage</div>
            <div class="led-wall">LED Wall</div>
            <div class="led-wall-text">LED Wall</div>
          </div>
          <div class="main-container">
            <div class="border-led border-top-right-led-wall"></div>
            <div class="border-led border-top-left-led-wall"></div>
            <div
              class="border-led border-bottom-center-led-display-panel"
            ></div>
            <div class="led-display-panel">LED Display Panel</div>
            <div class="bar-container">
              <div class="bar">
                Dragon Bar<br />&#40;酒龍吧&#41;
                <div class="rectangle" />
              </div>
              <div class="bar">
                The Balvenie<br />Bar
                <div class="rectangle" />
              </div>
            </div>
            <div class="door-container">
              <div class="door door-right" />
              <div class="door door-left" />
            </div>
            <div class="arrow-left" />
            <p class="ballroom-entrance">Ballroom<br />Entrance</p>
            <div class="section-container">
              <div class="left-section">
                <div class="left-section-columns">
                  <!-- Column 1 -->
                  <div
                    v-for="table in tableData
                      .filter((table, index) => index < 9)
                      .reverse()"
                    :key="table.table_name"
                    class="table-container"
                  >
                    <div
                      class="table-wrapper"
                      @click.stop="showModal(table.table_name)"
                    >
                      <div class="table">
                        <div>{{ table.table_name }}</div>
                      </div>
                      <div
                        v-if="
                          table.table_name === '5' ||
                          table.table_name === '29' ||
                          table.table_name === '25' ||
                          table.table_name === '17' ||
                          table.table_name === '35' ||
                          table.table_name === '31'
                        "
                      >
                        <div
                          v-for="chairIndex in 11"
                          :key="chairIndex"
                          :class="
                            'chair chair-extra chair-extra__' +
                            chairIndex +
                            (chairIndex <= table.total_checkin
                              ? ' checked-in'
                              : '')
                          "
                        >
                          <div class="chair-handler-container">
                            <span class="chair-handler"></span>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div
                          v-for="chairIndex in 10"
                          :key="chairIndex"
                          :class="
                            'chair chair__' +
                            chairIndex +
                            (chairIndex <= table.total_checkin
                              ? ' checked-in'
                              : '')
                          "
                        >
                          <div class="chair-handler-container">
                            <span class="chair-handler"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Column 2 -->
                  <div
                    v-for="table in tableData
                      .filter((table, index) => index >= 9 && index < 18)
                      .reverse()"
                    :key="table.table_name"
                    class="table-container"
                  >
                    <div
                      class="table-wrapper"
                      @click.stop="showModal(table.table_name)"
                    >
                      <div class="table">
                        <div>{{ table.table_name }}</div>
                      </div>
                      <div
                        v-if="
                          table.table_name === '5' ||
                          table.table_name === '29' ||
                          table.table_name === '25' ||
                          table.table_name === '17' ||
                          table.table_name === '35' ||
                          table.table_name === '31'
                        "
                      >
                        <div
                          v-for="chairIndex in 11"
                          :key="chairIndex"
                          :class="
                            'chair chair-extra chair-extra__' +
                            chairIndex +
                            (chairIndex <= table.total_checkin
                              ? ' checked-in'
                              : '')
                          "
                        >
                          <div class="chair-handler-container">
                            <span class="chair-handler"></span>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div
                          v-for="chairIndex in 10"
                          :key="chairIndex"
                          :class="
                            'chair chair__' +
                            chairIndex +
                            (chairIndex <= table.total_checkin
                              ? ' checked-in'
                              : '')
                          "
                        >
                          <div class="chair-handler-container">
                            <span class="chair-handler"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Column 3 -->
                  <div
                    v-for="table in tableData
                      .filter((table, index) => index >= 18 && index < 27)
                      .reverse()"
                    :key="table.table_name"
                    class="table-container"
                  >
                    <div
                      class="table-wrapper"
                      @click.stop="showModal(table.table_name)"
                    >
                      <div class="table">
                        <div>{{ table.table_name }}</div>
                      </div>
                      <div
                        v-if="
                          table.table_name === '5' ||
                          table.table_name === '29' ||
                          table.table_name === '25' ||
                          table.table_name === '17' ||
                          table.table_name === '35' ||
                          table.table_name === '31'
                        "
                      >
                        <div
                          v-for="chairIndex in 11"
                          :key="chairIndex"
                          :class="
                            'chair chair-extra chair-extra__' +
                            chairIndex +
                            (chairIndex <= table.total_checkin
                              ? ' checked-in'
                              : '')
                          "
                        >
                          <div class="chair-handler-container">
                            <span class="chair-handler"></span>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div
                          v-for="chairIndex in 10"
                          :key="chairIndex"
                          :class="
                            'chair chair__' +
                            chairIndex +
                            (chairIndex <= table.total_checkin
                              ? ' checked-in'
                              : '')
                          "
                        >
                          <div class="chair-handler-container">
                            <span class="chair-handler"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right-section">
                <div class="right-section-columns">
                  <!-- Column 1 -->
                  <div
                    v-for="table in tableData
                      .filter((table, index) => index >= 27 && index < 34)
                      .reverse()"
                    :key="table.table_name"
                    class="table-container"
                  >
                    <div
                      class="table-wrapper"
                      @click.stop="showModal(table.table_name)"
                    >
                      <div class="table">
                        <div>{{ table.table_name }}</div>
                      </div>
                      <div
                        v-if="
                          table.table_name === '5' ||
                          table.table_name === '29' ||
                          table.table_name === '25' ||
                          table.table_name === '17' ||
                          table.table_name === '35' ||
                          table.table_name === '31'
                        "
                      >
                        <div
                          v-for="chairIndex in 11"
                          :key="chairIndex"
                          :class="
                            'chair chair-extra chair-extra__' +
                            chairIndex +
                            (chairIndex <= table.total_checkin
                              ? ' checked-in'
                              : '')
                          "
                        >
                          <div class="chair-handler-container">
                            <span class="chair-handler"></span>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div
                          v-for="chairIndex in 10"
                          :key="chairIndex"
                          :class="
                            'chair chair__' +
                            chairIndex +
                            (chairIndex <= table.total_checkin
                              ? ' checked-in'
                              : '')
                          "
                        >
                          <div class="chair-handler-container">
                            <span class="chair-handler"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Column 2 -->
                  <div
                    v-for="table in tableData
                      .filter((table, index) => index >= 34 && index < 41)
                      .reverse()"
                    :key="table.table_name"
                    class="table-container"
                  >
                    <div
                      class="table-wrapper"
                      @click.stop="showModal(table.table_name)"
                    >
                      <div class="table">
                        <div>{{ table.table_name }}</div>
                      </div>
                      <div
                        v-if="
                          table.table_name === '5' ||
                          table.table_name === '29' ||
                          table.table_name === '25' ||
                          table.table_name === '17' ||
                          table.table_name === '35' ||
                          table.table_name === '31'
                        "
                      >
                        <div
                          v-for="chairIndex in 11"
                          :key="chairIndex"
                          :class="
                            'chair chair-extra chair-extra__' +
                            chairIndex +
                            (chairIndex <= table.total_checkin
                              ? ' checked-in'
                              : '')
                          "
                        >
                          <div class="chair-handler-container">
                            <span class="chair-handler"></span>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div
                          v-for="chairIndex in 10"
                          :key="chairIndex"
                          :class="
                            'chair chair__' +
                            chairIndex +
                            (chairIndex <= table.total_checkin
                              ? ' checked-in'
                              : '')
                          "
                        >
                          <div class="chair-handler-container">
                            <span class="chair-handler"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Column 3 -->
                  <div
                    v-for="table in tableData
                      .filter((table, index) => index >= 41 && index < 48)
                      .reverse()"
                    :key="table.table_name"
                    class="table-container"
                  >
                    <div
                      class="table-wrapper"
                      @click.stop="showModal(table.table_name)"
                    >
                      <div class="table">
                        <div>{{ table.table_name }}</div>
                      </div>
                      <div
                        v-if="
                          table.table_name === '5' ||
                          table.table_name === '29' ||
                          table.table_name === '25' ||
                          table.table_name === '17' ||
                          table.table_name === '35' ||
                          table.table_name === '31'
                        "
                      >
                        <div
                          v-for="chairIndex in 11"
                          :key="chairIndex"
                          :class="
                            'chair chair-extra chair-extra__' +
                            chairIndex +
                            (chairIndex <= table.total_checkin
                              ? ' checked-in'
                              : '')
                          "
                        >
                          <div class="chair-handler-container">
                            <span class="chair-handler"></span>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div
                          v-for="chairIndex in 10"
                          :key="chairIndex"
                          :class="
                            'chair chair__' +
                            chairIndex +
                            (chairIndex <= table.total_checkin
                              ? ' checked-in'
                              : '')
                          "
                        >
                          <div class="chair-handler-container">
                            <span class="chair-handler"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          class="red-cloud mobile"
          src="../../assets/img/mobile-dragon.png"
          alt="red-cloud"
        />
        <img
          class="ntt-icon mobile"
          src="../../assets/img/ntt-icon.png"
          alt="ntt-icon"
        />
      </div>
      <img
        class="dragon desktop"
        src="../../assets/img/dragon.png"
        alt="dragon"
      />
    </div>
    <!-- Popup Modal -->
    <div v-show="isShowModal" class="modal-overlay">
      <div class="modal-container">
        <div class="modal" ref="modal">
          <div class="modal__header">
            <div class="flex justify-between">
              <div class="font-bold">{{ selectedTableName }}</div>
              <div class="close-icon" @click="hideModal()">&#10006;</div>
            </div>
          </div>
          <div v-if="tableGuestList.length > 0" class="modal__body">
            <div class="checked-in-container">
              <div class="font-bold">Checked-In Guest&#40;s&#41;</div>
              <ol v-if="hasCheckedInGuests">
                <li
                  v-for="(guest, index) in tableGuestList.filter(
                    (guest) => guest.checkin_status === 1
                  )"
                  :key="index"
                >
                  {{ guest.full_name }}
                </li>
              </ol>
              <p v-else class="italic text-sm">No guests checked-in yet</p>
            </div>
            <div class="not-checked-in-container">
              <div class="font-bold">Pending Guests&#40;s&#41;</div>
              <ol v-if="hasPendingGuests">
                <li
                  v-for="(guest, index) in tableGuestList.filter(
                    (guest) => guest.checkin_status === 0
                  )"
                  :key="index"
                >
                  {{ guest.full_name }}
                </li>
              </ol>
              <p v-else class="italic text-sm">No guests available</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Search Popup Modal -->
    <div v-show="isShowSearchModal" class="modal-overlay">
      <div class="modal-container">
        <div class="modal" ref="searchModal">
          <div class="modal__header">
            <div class="modal__header__container">
              <div class="search-field">
                <input
                  type="text"
                  v-model="searchQuery"
                  placeholder="Search Guest(s)"
                  @input="handleSearchInput"
                />
              </div>
              <div class="close-icon" @click="hideSearchModal">&#10006;</div>
            </div>
          </div>
          <div class="modal__body">
            <div v-if="searchGuestResult.length > 0" class="modal__body__inner">
              <div class="checked-in-container">
                <div class="font-bold">Checked-In Guest&#40;s&#41;</div>
                <ol v-if="searchHasCheckedInGuests">
                  <li
                    v-for="(guest, index) in searchGuestResult.filter(
                      (guest) => guest.checkin_status === 1
                    )"
                    :key="index"
                  >
                    {{ guest.full_name }}
                  </li>
                </ol>
                <p v-else class="italic text-sm">No guests checked-in yet</p>
              </div>
              <div class="not-checked-in-container">
                <div class="font-bold">Pending Guests&#40;s&#41;</div>
                <ol v-if="searchHasPendingGuests">
                  <li
                    v-for="(guest, index) in searchGuestResult.filter(
                      (guest) => guest.checkin_status === 0
                    )"
                    :key="index"
                  >
                    {{ guest.full_name }}
                  </li>
                </ol>
                <p v-else class="italic text-sm">No guests available</p>
              </div>
            </div>
            <div v-else-if="searchQuery && searchGuestResult.length === 0">
              <p>No results found</p>
            </div>
            <div v-else>
              <p class="italic">Guest(s) data will appear here</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      width: 0,
      tableData: [],
      tableGuestList: [],
      isShowModal: false,
      isShowSearchModal: false,
      selectedTableName: "",
      isHeightOver: false,
      isDesktop: false,
      intervalId: null,
      searchQuery: "",
      searchGuestResult: [],
    };
  },
  computed: {
    hasCheckedInGuests() {
      return this.tableGuestList.some((guest) => guest.checkin_status === 1);
    },
    hasPendingGuests() {
      return this.tableGuestList.some((guest) => guest.checkin_status === 0);
    },
    searchHasCheckedInGuests() {
      return this.searchGuestResult.some((guest) => guest.checkin_status === 1);
    },
    searchHasPendingGuests() {
      return this.searchGuestResult.some((guest) => guest.checkin_status === 0);
    },
  },
  methods: {
    async fetchTableLayout() {
      try {
        const res = await axios.get(
          process.env.VUE_APP_API_ENDPOINT + "/table-layout"
        );
        this.tableData = res.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    startFetchingTableLayout() {
      this.fetchTableLayout();

      // Set interval to fetch every 10 seconds
      this.intervalId = setInterval(() => {
        this.fetchTableLayout();
      }, 10000);
    },
    onResize() {
      if (window.innerWidth > 900) {
        this.isDesktop = true;
      } else {
        this.isDesktop = false;
      }
      if (window.innerWidth <= 1400 && window.innerWidth > 900) {
        this.width = (window.innerWidth * (1200 / 1400)) / 1200;
      } else if (window.innerWidth <= 900) {
        this.width = (window.innerWidth * (1200 / 900)) / 1200;
      } else {
        this.width = 1;
      }
      this.isHeightOver = window.innerHeight >= 921;
    },
    async showModal(tableName) {
      this.selectedTableName = tableName;
      this.isShowModal = true;
      try {
        const res = await axios.get(
          process.env.VUE_APP_API_ENDPOINT + "/table-guest-list/" + tableName
        );
        this.tableGuestList = res.data;
      } catch (error) {
        console.error(error);
      }
    },
    hideModal() {
      this.isShowModal = false;
    },
    showSearchModal() {
      this.isShowSearchModal = true;
    },
    hideSearchModal() {
      this.isShowSearchModal = false;
    },
    handleClickOutside(event) {
      if (this.isShowModal) {
        const modal = this.$refs.modal;
        if (modal && !modal.contains(event.target)) {
          this.hideModal();
        }
      }
    },
    handleClickOutsideSearchModal(event) {
      if (this.isShowSearchModal) {
        const searchModal = this.$refs.searchModal;
        if (searchModal && !searchModal.contains(event.target)) {
          this.hideSearchModal();
        }
      }
    },
    async handleSearchInput() {
      try {
        const res = await axios.get(
          process.env.VUE_APP_API_ENDPOINT +
            "/table-guest-data?search=" +
            this.searchQuery
        );
        this.searchGuestResult = res.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    this.onResize();
    window.addEventListener("click", this.handleClickOutside);
    window.addEventListener("click", this.handleClickOutsideSearchModal);
    window.addEventListener("resize", this.onResize);
    window.addEventListener("orientationchange", this.onResize);

    this.startFetchingTableLayout();
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
    window.removeEventListener("click", this.handleClickOutside);
    window.removeEventListener("click", this.handleClickOutsideSearchModal);
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("orientationchange", this.onResize);
  },
};
</script>

<style lang="sass" scoped>
$primary: #070f26
$chair-yellow: #fee34e
$table-yellow: #ffcf12
$border-yellow: #f19506
$text-yellow: #fcdc02
$green: #54b848

@media (max-width: 900px)
  .desktop
    display: none

@media (min-width: 901px)
  .mobile
    display: none

.outer-container
  width: 100%
  background: $primary
  .inner-container
    position: relative
    background-size: 100%
    background-repeat: no-repeat
    overflow-x: hidden
    overflow-y: auto
    color: white
    line-height: 18px
    background-image: url("../../assets/img/background-image.png")
    @media (max-width: 900px)
      background-image: url("../../assets/img/mobile-bg-image.png")
    .logo-container
      display: flex
      justify-content: center
      padding: 20px
      img
        width: 80%
        max-width: 160px
    .red-cloud
      position: absolute
      bottom: 0
      left: 0
      width: 30%
      max-width: 300px
      @media (max-width: 900px)
        max-width: 100%
        width: 75%
    .ntt-icon
      position: absolute
      right: 10px
      bottom: 10px
      width: 20%
      max-width: 150px
    .dragon
      width: 100%
      max-width: 1400px
      margin: 0 auto
      padding: 0 160px 30px
    .search-icon
      max-width: 23px
      position: absolute
      top: 25px
      right: 3%
    .table-layout-container
      .table-layout
        position: relative
        display: flex
        margin: auto
        border: 1px solid white
        font-size: 1rem
        @media (max-width: 900px)
          font-size: 1.4rem
          line-height: 1.5rem
          margin-bottom: 20px
      .main-container
        position: relative
        width: 100%
        height: 100%
        .border-led
          position: absolute
        .bar-container
          position: absolute
          display: flex
          align-items: flex-start
          gap: 20px
          color: $text-yellow
          .rectangle
            justify-content: center
            background: white
          .bar
            display: flex
            align-items: center
            gap: 10px
        .door-container
          position: absolute
          display: flex
          background: transparent
          .door
            width: 30px
            height: 30px
            border: 1px solid white
            &-left
              border-radius: 30px 0 0 0
            &-right
              border-radius: 0 30px 0 0
        .section-container
          height: 100%
          display: flex
          gap: 50px
          @media (max-width: 900px)
            gap: 80px
          .table-container
            width: fit-content
            padding: 10px
            .table-wrapper
              position: relative
              width: fit-content
              cursor: pointer
              .table
                position: relative
                border: 1px solid $border-yellow
                border-radius: 100%
                min-width: 50px
                aspect-ratio: 1 / 1
                background: $table-yellow
                color: black
                z-index: 2
                display: flex
                justify-content: center
                align-items: center
              .chair
                position: absolute
                border: 1px solid $border-yellow
                width: 13px
                height: 13px
                z-index: 1
                background: $chair-yellow
                .chair-handler-container
                  position: relative
                  .chair-handler
                    position: absolute
                    width: 11px
                    height: 2px
                    top: 0
                    background: $chair-yellow
                    border-bottom: 1px solid #f19506
                &.checked-in
                  background: red
                  .chair-handler
                    background: red
                &__1
                  top: -18%
                  left: 50%
                  transform: translateX(-50%)
                &__2
                  top: -7%
                  left: 72%
                  transform: rotate(40deg)
                &__3
                  top: 20%
                  left: 90%
                  transform: rotate(70deg)
                &__4
                  top: 52%
                  left: 90%
                  transform: rotate(110deg)
                &__5
                  top: 80%
                  left: 72%
                  transform: rotate(140deg)
                &__6
                  top: 95%
                  left: 50%
                  transform: translateX(-50%) rotate(180deg)
                &__7
                  top: 80%
                  left: 2%
                  transform: rotate(-140deg)
                &__8
                  top: 52%
                  left: -15%
                  transform: rotate(-110deg)
                &__9
                  top: 20%
                  left: -15%
                  transform: rotate(-70deg)
                &__10
                  top: -7%
                  left: 2%
                  transform: rotate(-40deg)
                &.chair-extra
                  &__1
                    top: -18%
                    left: 20%
                    transform: rotate(-20deg)
                  &__2
                    top: -18%
                    right: 20%
                    transform: rotate(20deg)
                  &__3
                    top: 0%
                    right: -7%
                    transform: rotate(60deg)
                  &__4
                    top: 30%
                    right: -18%
                    transform: rotate(85deg)
                  &__5
                    bottom: 14%
                    right: -13%
                    transform: rotate(120deg)
                  &__6
                    bottom: -10%
                    right: 6%
                    transform: rotate(150deg)
                  &__7
                    bottom: -18%
                    left: 50%
                    transform: translateX(-50%) rotate(-180deg)
                  &__8
                    bottom: -10%
                    left: 6%
                    transform: rotate(-150deg)
                  &__9
                    bottom: 14%
                    left: -15%
                    transform: rotate(-120deg)
                  &__10
                    top: 30%
                    left: -18%
                    transform: rotate(-85deg)
                  &__11
                    top: 0%
                    left: -7%
                    transform: rotate(-60deg)
      &.mobile
        padding: 0 30px 170px
        transform-origin: top left
        width: 900px
        margin: 0 auto
        .table-layout
          aspect-ratio: 1 / 1.6
          flex-direction: column
          .top-container
            display: flex
            widows: 100%
            height: 18%
            .led-wall
              display: flex
              align-items: flex-end
              justify-content: center
              padding-bottom: 5px
              background-color: grey
              width: 20%
            .led-wall-text
              position: absolute
              top: 8px
              left: 50%
              transform: translateX(-50%)
            .stage
              display: flex
              align-items: center
              justify-content: center
              padding-top: 30px
              width: 60%
              background: linear-gradient(0deg, rgba(253,30,198,1) 0%, rgba(194,1,113,1) 100%)
              border-top: 5px solid #54b848
          .main-container
            padding: 40px 10px
            .border-led
              border-top: 5px solid $green
              &.border-top-left-led-wall
                top: 0
                left: 4.5%
                width: 80px
              &.border-top-right-led-wall
                top: 0
                right: 4.5%
                width: 80px
              &.border-bottom-center-led-display-panel
                bottom: 0
                left: 50%
                transform: translateX(-50%)
                width: 70%
            .led-display-panel
              position: absolute
              left: 50%
              bottom: 10px
              transform: translateX(-50%)
            .bar-container
              align-items: flex-end
              right: 8%
              bottom: 3.5%
              .bar
                flex-direction: column
                text-align: center
              .rectangle
                height: 40px
                width: 150px
            .door-container
              right: -22px
              bottom: 10.5%
              padding-bottom: 1px
              margin-bottom: -2.5px
              transform: rotate(-90deg)
              .door
                width: 40px
                height: 40px
                border-bottom: 2px solid $primary
            .arrow-left
              position: absolute
              bottom: 10.5%
              right: 5%
              transform: rotate(180deg)
              background: $table-yellow
              width: 30px
              height: 30px
              clip-path: polygon(0% 30%, 30% 30%, 30% 0%, 100% 50%, 30% 100%, 30% 70%, 0% 70%)
            .ballroom-entrance
              position: absolute
              bottom: 13.5%
              right: 6%
            .section-container
              flex-direction: row
              .table-wrapper
                transform: scale(1.5)
                transform-origin: top center
                font-size: 1rem
              .left-section
                width: 50%
                .left-section-columns
                  display: grid
                  justify-items: center
                  grid-auto-flow: column
                  grid-template-columns: repeat(1, 120px)
                  grid-template-rows: repeat(9, 140px)
              .right-section
                width: 50%
                height: 80%
                margin-bottom: auto
                .right-section-columns
                  display: grid
                  justify-items: center
                  grid-auto-flow: column
                  grid-template-columns: repeat(1, 120px)
                  grid-template-rows: repeat(7, 140px)

      &.desktop
        padding: 0 150px
        height: 100%
        transform-origin: top left
        width: 1400px
        margin: 0 auto
        .red-scroll
          position: absolute
          top: 0
          max-width: 90px
          &__left
            left: 0
          &__right
            right: 0
        .table-layout
          justify-content: flex-end
          aspect-ratio: 1.8 / 1
          .main-container
            padding: 10px 25px
            .border-led
              border-left: 5px solid $green
              &.border-top-right-led-wall
                border-left: 5px solid $green
                top: 3%
                right: 0
                height: 80px
              &.border-bottom-right-led-wall
                border-left: 5px solid $green
                bottom: 3%
                right: 0
                height: 80px
              &.border-left-led-display-panel
                left: 0
                top: 50%
                transform: translateY(-50%)
                height: 60%
            .led-display-panel
              position: absolute
              left: 0
              top: 50%
              transform: translate(-35%, -50%) rotate(-90deg)
            .bar-container
              flex-direction: column
              left: 5%
              bottom: 10%
              .rectangle
                width: 30px
                height: 110px
            .door-container
              bottom: 0
              left: 10%
              padding-bottom: 1px
              margin-bottom: -2.5px
              .door
                width: 30px
                height: 30px
                border-bottom: 2px solid $primary
            .arrow-up
              position: absolute
              bottom: 30px
              left: 11.5%
              transform: rotate(-90deg)
              background: $table-yellow
              width: 30px
              height: 30px
              clip-path: polygon(0% 30%, 30% 30%, 30% 0%, 100% 50%, 30% 100%, 30% 70%, 0% 70%)
            .ballroom-entrance
              position: absolute
              bottom: -7%
              left: 10%
            .section-container
              flex-direction: column
              .top-section
                height: 50%
                display: grid
                justify-items: center
                grid-template-columns: repeat(9, 1fr)
              .bottom-section
                height: 50%
                width: 80%
                margin-left: auto
                display: grid
                justify-items: center
                grid-template-columns: repeat(7, 1fr)
          .right-container
            display: flex
            flex-direction: column
            justify-content: stretch
            width: 200px
            height: 100%
            .led-wall
              display: flex
              align-items: center
              padding: 10px
              background-color: grey
              height: 20%
            .led-wall-text
              position: absolute
              top: 50%
              right: -1%
              transform: translateY(-50%) rotate(-90deg)
            .stage
              display: flex
              align-items: center
              padding: 10px 10px 10px 30px
              height: 60%
              background: linear-gradient(90deg, rgba(253,30,198,1) 0%, rgba(194,1,113,1) 100%)
              border-right: 5px solid #54b848

  .modal-overlay
    position: fixed
    left: 0
    right: 0
    top: 0
    bottom: 0
    background: rgba(0, 0, 0, 0.5)
    .modal-container
      display: flex
      width: 100%
      height: 100%
      justify-content: center
      align-items: center
      .modal
        background: #0d1e4a
        width: 100%
        height: 100%
        max-width: 500px
        max-height: 500px
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5)
        color: white
        margin: 15px
        overflow-y: auto
        &__header
          padding: 20px
          border-bottom: 0.5px solid lightgrey
          .close-icon
            color: transparent
            text-shadow: 0 0 0 rgb(255, 255, 255)
          &__container
            display: flex
            justify-content: space-between
            width: 100%
            gap: 1rem
          .search-field
            width: 100%
            input
              background: transparent
              width: 100%
              border-color: white
              &::placeholder
                color: white
                opacity: 0.6
        &__body
          padding: 20px
          display: flex
          flex-direction: column
          gap: 30px
          &__inner
            display: flex
            flex-direction: column
            gap: 30px
          ol
            list-style-type: alpha
            li
              margin-left: 1.2rem
</style>
