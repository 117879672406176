import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app

import App from "@/App.vue";

// views without layouts

import Index from "@/views/Index.vue";
import CheckIn from "@/views/CheckIn/Index";
import CheckInDetail from "@/views/CheckIn/[id].vue";
import ViewCode from "@/views/ViewCode/[id].vue";
import ViewCodeInfo from "@/views/ViewCodeInfo/[id].vue";
import LiveTable from "@/views/LiveTable/Index";

// routes
const routes = [
  {
    path: "/",
    component: Index,
  },
  {
    path: "/check-in",
    component: CheckIn,
  },
  {
    path: "/check-in/:id",
    component: CheckInDetail,
    props: true,
  },
  {
    path: "/view-code/:id",
    component: ViewCode,
  },
  {
    path: "/view-code-info/:id",
    component: ViewCodeInfo,
  },
  {
    path: "/live-table",
    component: LiveTable,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);
app.use(router);
app.mount("#app");
